<template lang="html">
  <div style="overflow:auto;max-height:500px;">
    <v-list flat>
      <v-list-item v-for="(item,i) in filtered" :key="i">
        <v-list-item-content>
          <v-list-item-title style="overflow-wrap: break-word;text-overflow:initial;overflow:visible;white-space:normal">{{item.nama_aktifitas}}</v-list-item-title>
          <v-list-item-subtitle class="green--text text--lighten-2">{{item.waktu}} menit</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn class="green" dark>Lapor</v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  props:{
    filtered:Array
  }
}
</script>

<style lang="css" scoped>
</style>
